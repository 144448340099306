

body {
  /*
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

   */
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 60px;
  color: #4a5669;
  line-height: 1;
  font-size: 16px;
  font-weight: normal;
  background-color: #ffffff;

}

.footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  color: grey;
  text-align: center;
  font-size: 14px
}

@media screen and (max-width: 1024px){
  body{
    margin-bottom: 0;
  }
  .footer {
    position: relative;
  }
}

.MuiOutlinedInput-notchedOutline {
  border-color: white;
}
.MuiFormLabel-root {
  color: white;
}
.MuiInputBase-input {
  color: white;
}

.left {
  float: left;
  width: 20%;
}

.main {
  float: left;
  width: 60%;
}

.right {
  float: left;
  width: 20%;
}

@media screen and (max-width: 800px) {
  .left, .main, .right {
    width: 100%; /* The width is 100%, when the viewport is 800px or smaller */
  }
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
